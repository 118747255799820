@import "variables"

#click-effect
  position: fixed
  top: 50%
  left: 50%
  z-index: 100
  transform: translate(-50%, -50%)
  height: 0
  width: 0
  aspect-ratio: 1 / 1
  border-radius: 50%
  box-sizing: border-box
  filter: blur(1px)
  border: solid 0 $primary-color
  pointer-events: none
