#click-effect {
  z-index: 100;
  aspect-ratio: 1;
  box-sizing: border-box;
  filter: blur(1px);
  pointer-events: none;
  border: 0 solid #ae9ca6;
  border-radius: 50%;
  width: 0;
  height: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=index.181dcef6.css.map */
